import * as fbMgr from "./FirebaseMgr";

var database = fbMgr.database;

let gameId = '';
var usersRef, gameRef;
let userFirebaseId;


const gameInitObj = {
    users: "",
    gameData : {
        state: "waitForPlayers",
        round: -1,
        serverShouldProcess : false
    },
    userSubmissions : ""
}

async function generateGameId(){
    return new Promise((resolve,reject)=>{
        var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
        var charactersLength = characters.length;
        
        gameId = "";
        for (var i = 0; i < 4; i++) {
            gameId += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        fbMgr.checkIfGameIdIsAvailable(gameId).then(()=>{
            console.log("[GameGenerator] checkIfGameIdIsAvailable resolved, so resolving");
            resolve(gameId);
        }).catch(()=>{
            console.error("[GameGenerator] checkIfGameIdIsAvailable rejected, so rejecting");
            reject();
        }
        )
    })
}

export async function generateGame() {
    return new Promise((resolve,reject)=>{
        var ref = database.ref("games");

        console.log(ref);

        generateGameId().then((gameId)=>{
            if (ref != null) {
                console.log("About to add gameId to database: " + gameId);
    
                // create an entry for this game in the database, and populate it with an empty users list
                ref.child(gameId).set(gameInitObj);
    
                // save a reference to the game object and users object
                gameRef = ref.child(gameId);
                usersRef = gameRef.child("users");
    
                console.log("Storing refs: " + JSON.stringify(gameRef) + " " + JSON.stringify(usersRef))
                fbMgr.storeRefs(gameRef,usersRef);
    
                resolve(gameId);
            }
        }).catch((error)=>{
            reject(error);
        })

    })
    
}

export function createNewGame(){
    return new Promise(function(resolve,reject){

        generateGameId().then((gameId)=>{
            
        })
        
    })
}

export function getGameId(){
    return gameId;
};


/* Part of improvements to try and handle people joining midway through
export async function joinGame(gameId){
    let _this = this;
    return new Promise(function(resolve,reject){
        var ref = database.ref("games");
        _this.gameRef = ref.child(gameId);
        fbMgr.storeGameRef(gameRef);
        resolve(_this.gameRef);
    })
    
}*/

// REVERTED TO THIS FROM V0.8.3
export async function joinGame(gameId){
    var ref = database.ref("games");
    gameRef = ref.child(gameId);
    fbMgr.storeGameRef(gameRef);
    return gameRef;
}