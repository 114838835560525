// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
var firebase = require("firebase/app");

// Add the Firebase products that you want to use
require("firebase/auth");
//require("firebase/firestore");
require("firebase/database");
require("firebase/analytics");
require("firebase/storage");


var firebaseConfigProd = {
    apiKey: "AIzaSyAbKEUUaFu9UFsulCfVdOaoKBAjjXz7Jv8",
    authDomain: "sillysketch-aaeb6.firebaseapp.com",
    databaseURL: "https://sillysketch-aaeb6.firebaseio.com",
    projectId: "sillysketch-aaeb6",
    storageBucket: "sillysketch-aaeb6.appspot.com",
    messagingSenderId: "905996141573",
    appId: "1:905996141573:web:139b30ce3afe39222b086c",
    measurementId: "G-5JYFLHTM69"
};

var firebaseConfigStage = {
    apiKey: "AIzaSyDeEXq5ndt_mEifHtU0xF3rksHdAF-c4s4",
    authDomain: "sillysketchstaging.firebaseapp.com",
    databaseURL: "https://sillysketchstaging.firebaseio.com",
    projectId: "sillysketchstaging",
    storageBucket: "sillysketchstaging.appspot.com",
    messagingSenderId: "899081028232",
    appId: "1:899081028232:web:5c7cc3fbcb74f4ac5b953d",
    measurementId: "G-E57R774Y22"
};

let gameRef, usersRef, connectionStateCb;
let previousConnectionState = false;

// Initialize Firebase
if(window.location.origin==="https://sillysketch.com"){
    firebase.initializeApp(firebaseConfigProd);
}else{
    firebase.initializeApp(firebaseConfigStage);
}

firebase.analytics();
let db = firebase.database();
let ref = db.ref("games");

// authenticate app
firebase.auth().signInAnonymously().catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
  });


export function initialiseFirebaseMgr(){
    console.log("[FirebaseMgr] environment = " + JSON.stringify(process.env.NODE_ENV))
    console.log("[FirebaseMgr] initialiseFirebase called");
    ref = db.ref("games");
    //firebase.analytics().logEvent("initialiseFirebaseCalled");
}

export async function checkIfGameIdIsAvailable(gameId){
    console.log("[FirebaseMgr] checkIfGameIdIsAvailable invoked, gameId: " + gameId)
    return new Promise((resolve,reject)=>{
        let gameRef = ref.child(gameId);
        gameRef.once("value").then(snapshot=>{
            if(snapshot.exists()){
                console.log("[FirebaseMgr] checkIfGameIdIsAvailable, gameId exists in database so rejecting promise");
                reject(new Error(gameId + " already exists in database"));
            }else{
                console.log("[FirebaseMgr] checkIfGameIdIsAvailable, gameId doesn't exist in database so resolving promise");
                resolve();
            }
        })
    })
}

export function initialiseDisconnectHandler(callback){
    console.log("[FirebaseMgr] initialiseDisconnectHandler called, callback: " + callback + " previousConnectionState: " + previousConnectionState);
    
    // store callback to use later
    connectionStateCb = callback;

    // see https://firebase.google.com/docs/database/web/offline-capabilities#section-connection-state
    var connectedRef = firebase.database().ref(".info/connected");
    connectedRef.on("value", function(snap) {
        if (snap.val() === true && previousConnectionState === false && connectionStateCb!==null && connectionStateCb!==undefined) {
            previousConnectionState = true;
            console.error("[FirebaseMgr] DB CONNECTED, calling callback");
            connectionStateCb(snap.val());

        } else if(snap.val() === false && previousConnectionState === true && connectionStateCb!==null & connectionStateCb!==undefined){
            previousConnectionState = false;
            console.error("[FirebaseMgr] DB DISCONNECTED, calling callback");
            connectionStateCb(snap.val());
        }
    });
}

export function logToFbAnalytics(event, data){
    console.log("[FirebaseMgr] logToFbAnalytics, event: " + event + " data: " + JSON.stringify(data));
    if(data!=null && data!=undefined) firebase.analytics().logEvent(event,data);
    else  firebase.analytics().logEvent(event);
}

export function getGameRef(gameId){
    console.log("[FirebaseMgr] game ref requested for gameId: " + gameId);
    if(gameRef==null && gameId!==null && gameId!==undefined){
        gameRef = ref.child(gameId);
        return gameRef;
    }else{
        return gameRef;
    }
}

export function getPrevGameRef(id){
    return new Promise(function(resolve,reject){
        console.log("[FirebaseManager] getPrevGameRef, id: " + id);
        if(ref==null || ref==undefined){
            console.log("[FirebaseManager] getPrevGameRef - main ref null");
            ref = db.ref("games");
            console.log("[FirebaseManager] getPrevGameRef - main ref now: " + ref);
        }

        let prevGameRef = ref.child(id);

        console.log("[FirebaseManager] getPrevGameRef: " + JSON.stringify(prevGameRef));

        if(prevGameRef != null && prevGameRef != undefined) resolve(prevGameRef);
        else reject("Unable to obtain prevGameRef");
    })
}

export function storeRefs(game, users){
    gameRef = game;
    usersRef = users;
    console.log("[FirebaseMgr] stored gameRef: " + JSON.stringify(gameRef));
    console.log("[FirebaseMgr] stored usersRef: " + JSON.stringify(usersRef));
}

export function getUsersRef(){
    console.log("[FirebaseMgr] usersRef requested: " + JSON.stringify(gameRef));
    return usersRef;
}

export function getMasterRef(){
    if(ref!=null) return ref;
}

export function storeGameRef(ref){
    gameRef = ref;
}

export function checkIfGameActive(gameId){
    let _this = this;
    return new Promise(function(resolve, reject){
        if(ref !== undefined && ref !== null ){
            
            ref.child(gameId).child("gameData").once("value",function (snapshot) {
                if(snapshot.val()!==null)
                {
                    console.log("[FirebaseManager] checkIfGameActive, gameData: " + JSON.stringify(snapshot.val().state));
                    if(snapshot.val().state==="finished" || snapshot.val().state==="resultsFinished" || snapshot.val().state==="resultsFinished") reject(false);
                    else resolve(snapshot.val().state);
                }
                else{
                    reject(false);
                }

            }).catch((error)=>{
                console.log("[FirebaseManager] checkIfGameActive, error: " + error);
                reject(false);
            })
        }else{
            reject(false);
        }
    });
}

//exports.database = firebase.database();
export var database = firebase.database();
export var storage = firebase.storage();