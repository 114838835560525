import React from "react";
import {Redirect} from 'react-router';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({hasError: true});
        // You can also log the error to an error reporting service

        console.error("[ErrorBoundary] error: " + error + " info: " + info);

        window.location.href = "http://www.sillysketch.com";
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong, redirecting you to home.</h1>;
           // return <Redirect to="/" />
        }
        return this.props.children;
    }
}

export default ErrorBoundary;