import React, {useState, Suspense, useEffect} from "react";
import {Route, NavLink, BrowserRouter, Switch, Redirect} from "react-router-dom";
//import Home from "./Home";
import FOF from "./404";
import * as cookieHandler from "./utils/CookieHandler";
import * as fbMgr from "./utils/FirebaseMgr";
import * as visHandler from "./utils/AppVisibilityHandler";
import "./index.css";
import packageJson from '../package.json';
//import Footer from "./components/Footer"
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Menu,
    MenuItem,
    Link,
    Grid,
    IconButton
} from "@material-ui/core";
//import {Typography, IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/core/styles';
//import TimerWidget from "./components/TimerWidget";
import DeviceDetector from "device-detector-js";
//import { reject } from "q";

import ErrorBoundary from "./utils/ErrorBoundary";

import RejoinPopup from "./components/RejoinPopup";
// import PreGameView from "./PreGameView";
// import Join from "./Join";
// import GameView from "./GameView";
// import About from "./About";
// import JoinFromUrl from "./JoinFromUrl";
// import ResultsFromUrl from "./ResultsFromUrl";
// import PreviousGames from "./PreviousGames";
// import Terms from "./Terms";
// import ErrorPage from "./ErrorPage";
// import Contact from "./Contact";
// import DebugPage from "./DebugPage";
// import RejoinLoading from "./RejoinLoading";


const Home = React.lazy(() => import("./Home"));
const PreGameView = React.lazy(() => import("./PreGameView"));
const JoinUsingCode = React.lazy(() => import("./JoinUsingCode"));
const GameView = React.lazy(() => import("./GameView"));
const About = React.lazy(() => import("./About"));
const JoinFromUrl = React.lazy(() => import("./JoinFromUrl"));
const ResultsFromUrl = React.lazy(() => import("./ResultsFromUrl"));
const PreviousGames = React.lazy(() => import("./PreviousGames"));
const Terms = React.lazy(() => import("./Terms"));
const ErrorPage = React.lazy(() => import("./ErrorPage"));
const Contact = React.lazy(() => import("./Contact"));
const DebugPage = React.lazy(() => import("./DebugPage"));
const RejoinLoading = React.lazy(() => import("./RejoinLoading"));
const CookieConsent = React.lazy(() => import("react-cookie-consent"));


const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#aaaaf2',
            main: "#7979f3",
            dark: '#4f4ff7'
        },
        secondary: {
            main: '#f44336'
        }
    },
    typography: {
        useNextVariants: true,
    }
});

const headerFont =  "'Patrick Hand', cursive";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },

    title: {
        flexGrow: 1,
        justifySelf: "center",
        fontFamily: headerFont
    },
    appbar: {
    //   alignItems: 'center',
    //   justifyItems: "center"
    }
}));

fbMgr.initialiseFirebaseMgr();

//let prevGameIsActive = false;
let prevGameId = null;
let prevPlayerId = null;
let prevPlayerName = null;
let previousGameState = null;



let timerOnView = false;
let timerInitTime = null;
let timeElapsedCb = null;


const isSupportedBrowser = userAgent => {
    // if (userAgent.trim() === (this.state.userAgent || "").trim()) {
    //     return this.setState({userAgent});
    // }

    let deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(userAgent);
    const browserName = (device!==null && device!== undefined) ? device.client.name : null;

    console.log("[Main] isSupportedBrowser, name: " + browserName + " is supported: " + !browserName.includes("book"));

    if(browserName.includes("book")){
        return false;
    }

    return true;
};

let hasCheckCookies = false;
let rejoinConfirmed = false;
let initComplete = false;
let allowAccess = true;

function Main() {

    const [showRejoinPopup, setRejoinPopup] = useState(false);

    // Use this hook to perform initialisation only once the DOM has rendered
    useEffect(()=>{
        // protect against calls to navigator before DOM is loaded, due to transition to SSR
        if(!initComplete && typeof window !== 'undefined' && window !== null && typeof window.navigator !== 'undefined' && window.navigator !== null){   
            initComplete = true;     
            allowAccess = isSupportedBrowser(navigator.userAgent);
            visHandler.registerAppVisibilityChangeListener();
            console.log("[Main] window innerHeight: " + window.innerHeight + " + innerWidth: " + window.innerWidth + " outerHeight: " + window.outerHeight + " outerWidth: " + window.outerWidth);
            if(!hasCheckCookies){
        
                // check whether previous game is still active
                cookieHandler.cookieManagerInit().then((result)=>{
                    cookieHandler.getCurrentGameFromCookie().then((gameObj)=>{
                        console.log("[Main] cookie handling, current game: " + JSON.stringify(gameObj));
                
                        prevGameId = gameObj.gameId;
                        prevPlayerId = gameObj.playerId;
                        prevPlayerName = gameObj.name;
                
                        // Query whether the game is still active
                        fbMgr.checkIfGameActive(prevGameId).then((state) => {
                            console.log("[Main] cookie handling, previous game state: " + state);
        
                            previousGameState = state;
        
                            if(state!=="finished" && state!=="resultsFinished" && state!=="waitForPlayers") setRejoinPopup(true);
                            
                        })
                
                    }).catch((error)=>{
                        console.log("[Main] cookie handling - active game check, error: " + error);
                    });
                })
            }
        
            hasCheckCookies = true;
        
            // dummy listeners to enable touch to work on iOS
            window.addEventListener("touchstart", {passive: true});
            window.addEventListener("touchcancel", {passive: true});
            window.addEventListener("touchend", {passive: true});
            window.addEventListener("touchmove", {passive: true});
        }
    })
    

    const classes = useStyles();
    const [anchorEl,
        open] = React.useState(null);
    const handleClick = event => {
        open(event.currentTarget);
    };

    const handleClose = () => {
        open(null);
    };

    const showTimer = function(time,cb){
        timerOnView = true;
        timerInitTime = time;
        timeElapsedCb = cb;
        console.log("[Main] showTimer, time: " + time);
    }
    
    
    const rejoin = (userConfirmed, gameId) => {
        setRejoinPopup(false);
        console.log("[Main] rejoin, userConfirmed: " + userConfirmed + " gameId: " + gameId);
        
        rejoinConfirmed = userConfirmed;
        if(rejoinConfirmed) fbMgr.logToFbAnalytics("rejoin-confirmed");
    }

    console.log("[Main] showRejoinPopup: " + showRejoinPopup);

    //showTimer(120, null)
    return (
    
        <ErrorBoundary>
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <AppBar position="sticky"
                
                className={classes.appbar}>
                    <Toolbar>
                        <Grid container direction="row" justify={"space-between"} alignItems="center">
                            <Grid item xs={1}>
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={handleClick}>
                                    <MenuIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={8} align="center">
                                <Typography variant="h4" className={classes.title}>
                                    <Link href="/" variant="h4" color="inherit" className={classes.title}>Silly Sketch</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>

                            </Grid>
                        </Grid>
                        
                        
                    </Toolbar>
                    <div>
                        <Menu
                            id="Menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}>
                            <MenuItem onClick={handleClose}>
                            <NavLink to="/">Home</NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              <NavLink to="/About">About</NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/PreviousGames">Previous Games</NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/Contact">Contact</NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                            <NavLink to="/Terms">Privacy and Terms of Use</NavLink>
                            </MenuItem>
                            {/* <MenuItem onClick={handleClose}>
                            <NavLink to="/DebugPage">DEBUG</NavLink>
                            </MenuItem> */}

                        </Menu>
                    </div>
                </AppBar>
                <div>
                    <div className="content">
                    <Suspense fallback={<Typography variant="h5" align="center">Loading...</Typography>}>

                        <Switch>

                         {/*
                            REMOVING THIS FIXED ISSUE WHERE ONLY THE HOME PAGE WAS LOADING!
                            <Route path="*" component={Home}/> */} 
                            <Route exact path="/" render={(props) => <Home test={showTimer} {...props} />}/>
                            
                            <Route path="/About" component={About}/>
                        
                            <Route path="/PreGameView" component={PreGameView} props/>
                            <Route path="/JoinUsingCode" component={JoinUsingCode}/>
                            <Route path="/GameView" component={GameView}/>
                            <Route path="/JoinFromUrl" component={JoinFromUrl}/>
                            <Route path="/ResultsFromUrl" component={ResultsFromUrl}/>
                            <Route path="/Terms" component={Terms}/>
                            <Route path="/ErrorPage" component={ErrorPage}/>
                            <Route path="/PreviousGames" component={PreviousGames}/>
                            <Route path="/Contact" component={Contact}/>
                            {/* <Route path="/DebugPage" component={DebugPage}/> */}
                            <Route path="/RejoinLoading" component={RejoinLoading}/>
                            <Route component={FOF} />
                            {/* <Redirect to="/FOF" /> */}
                        </Switch>
                        </Suspense>
                    </div>
                {showRejoinPopup && <RejoinPopup open={true} rejoin={rejoin} gameId={prevGameId}/>}


                {rejoinConfirmed && <Redirect to={{
                    pathname : "/RejoinLoading",
                    state : { prevGameId, prevPlayerName, prevPlayerId, previousGameState}
                }}/>}


                {!allowAccess && <Redirect to={{
                    pathname : "/ErrorPage",
                    state : { errorMessage: "Please copy the link you were sent and open it in the Chrome, Safari or Firefox app.",
                secondaryMessage : "Unfortunately, Silly Sketch does not work in browsers such as Facebook popups."}
                    }}/>  }
                </div>
                <div>
                    <Suspense fallback={<div>Loading cookie widget...</div>}>
                        <CookieConsent>This website uses cookies for anonymised analytics and to allow
                            users to revisit previous games. By continuing to use this site you accept the
                            usage of cookies, and the Terms of Usage.</CookieConsent>
                    </Suspense>
                   
                    <p>© 2020. Created by 
                        <a href="https://www.linkedin.com/in/ernarrowsmith/"> Ernie Arrowsmith</a>
                    </p>
                    <p>Email me at sillysketchgame@gmail.com</p>
                    <p>Beta build {packageJson.version}</p>
                </div>
            </BrowserRouter>
        </MuiThemeProvider>
        </ErrorBoundary>
    );
};

export default Main;