import React, {Component, useEffect , useState} from "react";
import {Redirect} from 'react-router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function test() {}

let hasOpened = false;
let newPrompt = null;
let gameViewRef = null;

export default function RejoinPopup(props) {

    console.log("[RejoinPopup] initialised with props: " + JSON.stringify(props));
    
    const [open,
        setOpen] = React.useState(false);

    const handleClickOpen = () => {
        hasOpened = true;
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.rejoin(false, props.gameId);
    };

    const handleRejoin = () => {
        setOpen(false);
        props.rejoin(true, props.gameId);
    };

    // this is a Hook API that's equivalent to componentDidMount, componentDidUpdate and componentDidUnmount
    useEffect(() => {
        handleClickOpen()
        // The cleanup function is effectively the same as unmount
        return function cleanup() {
            newPrompt = null;
            //hasOpened = false;
        };
      });
        
    
    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
     >
                <DialogContent>
                    <DialogContentText>
                    Game {props.gameId} is still in progress, would you like to rejoin?
                    </DialogContentText>
                </DialogContent>
                
                <DialogActions>
                <DialogActions>
                    <Button onClick={handleRejoin} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                </DialogActions>

                </DialogActions>
            </Dialog>
        </div>
    );
}
