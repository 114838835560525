import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
 
// ReactDOM.render(
//   <Main/>, 
//   document.getElementById("root")
// );
//console.log(process.env);
//if(process.env.NODE_ENV==="production"){
if(window.location.origin==="https://sillysketch.com"){
  console.log = function(){};
  console.debug = function(){};
  console.warn = function(){};
  console.error = function(){};
}

/**
 * pre-rendering using react snap https://web.dev/prerender-with-react-snap/
 */
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Main />, rootElement);
} else {
  ReactDOM.render(<Main />, rootElement);
}