import * as fbMgr from "./FirebaseMgr.js";
import * as gameGenerator from "./GameGenerator.js";
import * as gameMgr from "./GameManager.js";
import * as cookieHandler from "./CookieHandler";

var database = fbMgr.database;
let userFirebaseId,
    allUsersRef,
    userRef,
    allGamesRef,
    currentGameRef;
let playerList = [];
let userKeys = [];
let currentSequence;
let startPos = null;
let previousSubmissionState = null;
let promptNotAlreadyProvided = true;
let notifiedOfStartPos = false;
let hostChangeCb = null;
let hostStatus = null;
let appInForeground = false;

export function getUserId() {
    return userFirebaseId;
}

export function registerHostStateListener(cb){
    hostChangeCb = cb;
}

export async function getUserKeys() {
    return userKeys;
}

export async function getPlayerNames() {
    console.log("[UserManager] getPlayerNames = " + JSON.stringify(playerList));
    return playerList;
}

export function setVisibility(state){
    console.log("[UserManager] setVisibility invoked, state = " + state);
    appInForeground = state;
}

/**
 * 
 * @param {*} returnRef 
 * @param {*} onlyDisconnect true = only process and notify of any disconnects
 */
export function registerUserListChangeListener(returnRef, onlyDisconnect) {

    console.log("[UserManager] registerUserListChangeListener, return: " + returnRef + " onlyDisconnect: " + onlyDisconnect);

    if (allUsersRef == null) {
        return false;
    }

    /*var gameId = gameGenerator.getGameId();
    var ref = fbMgr.getMasterRef();
    var gameRef = ref.child(gameId);
    allUsersRef = gameRef.child("users");
    console.log(JSON.stringify(ref))
    console.log(JSON.stringify(gameRef))*/
    console.log(JSON.stringify(allUsersRef))

    allUsersRef.on("value", function (usersSnapshot) {
        console.log("[UserManager] allUsersRef update")

        let usersArr = [];

        // if a new player has joined
        usersSnapshot.forEach(function (childSnapshot) {

            usersArr.push(childSnapshot.name);
            // check whether this user is new, if so, store their key and value
            var key = childSnapshot.key;
            if (!userKeys.includes(key) && !onlyDisconnect) {
                console.log("[UserManager] New user!");
                userKeys.push(childSnapshot.key);
                playerList.push(childSnapshot.val().name);
                //let name = playerList[key].name;
                returnRef.updatePlayerList(playerList);
            }else if(childSnapshot.val().stillInGame === false){
                console.log("[UserManager] user disconnected: " + childSnapshot.val().name);

                // remove username from players list (note, if there are two, this should only remove the first one)
                let playerNameToRemoveIndex = playerList.indexOf(childSnapshot.val().name);
                if(playerNameToRemoveIndex > -1) playerList.splice(playerNameToRemoveIndex, 1);
                let playerKeyToRemoveIndex = userKeys.indexOf(childSnapshot.key);
                if(playerKeyToRemoveIndex > -1) userKeys.splice(playerKeyToRemoveIndex, 1);

                console.log("[UserManager] after removing " + childSnapshot.val().name + " (" + childSnapshot.key + "), keys =  " + userKeys + " playerList = " + playerList);

                returnRef.playerDisconnected(childSnapshot.val().name, playerList);
            } 
        });

        if(!onlyDisconnect) returnRef.updatePlayerList(playerList);
    });

    // register listener for the specific user

    console.log("user listener registered successfully")
    return true;

}

export function setSubmissionState(state, round) {
    console.log("[UserManager] setSubmissionState: " + state, " previousSubmissionState: " + previousSubmissionState + " round: " + round);

    if (state != previousSubmissionState) {
        // userRef.update({     "submitted": state,     "serverShouldProcess" : true });
        let userSubmittedPath = "users/" + userFirebaseId + "/submitted";
        let userRoundPath = "users/" + userFirebaseId + "/lastSubmittedRound";
        let userSubmissionObjRoundNum = "userSubmissions/" + userFirebaseId;
        let updateData = {
            "serverShouldProcess" : true
        };
        updateData[userSubmittedPath] = state;
        
        if(round!== null && round!==undefined){
            updateData[userRoundPath] = round;
            updateData[userSubmissionObjRoundNum] = round;
        }
        var d = new Date();
        console.log("[UserManager] timestamp just before updating db: " + d.getTime());
        console.log("[UserManager] about to update game with data: " + JSON.stringify(updateData));
        currentGameRef.update(updateData)
    }

    previousSubmissionState = state;
}


export function setCurrentSeqNum(seqNum){
    console.log("[UserManager] setCurrentSeqNum: " + seqNum);

    let updateData = {
        "currentSequence": seqNum
    };
    console.log("[UserManager] setCurrentSeqNum, updateData: " + JSON.stringify(updateData));
    currentGameRef.child("users").child(userFirebaseId).update(updateData)

}

/* Trying to stop people joining mid game
export async function loginToGame(playerName, gameId, isHost) {

    return new Promise(function (resolve, reject) {
        console.log("[UserManager] loginToGame called, isHost: " + isHost);

        playerList.push(playerName);

        if (!isHost) {
            // need to get a reference to this game first
            gameGenerator.joinGame(gameId).then(joinRes =>{
                currentGameRef = joinRes;
                allUsersRef = currentGameRef.child("users");
                if(allUsersRef !== null){
                    resolve(allUsersRef);
                }else{
                    reject(-2);
                }
                
            });
        } else {
            currentGameRef = fbMgr.getGameRef();
            allUsersRef = fbMgr.getUsersRef();
            if(allUsersRef !== null){
                resolve(allUsersRef);
            }else{
                reject(-2);
            }
        }
    }).then(allUsersRef => {
        if (allUsersRef !== null && allUsersRef !== undefined) {

            console.log("[UserManager] allUsersRef!=null");

            // check if game is already in progress
            currentGameRef.child("gameData").child("state").once("value").then(function (data) {
                let gameState = data.val();
                console.log("[UserManager] loginToGame, current game state: " + JSON.stringify(gameState));
                if (gameState == "waitForPlayers") {
                    // push a new unique user into the user object and store the unique ID of this user
                    userFirebaseId = allUsersRef.push({
                        name: playerName,
                        currentSequence: "",
                        sequences: {},
                        isHost: isHost,
                        stillInGame: true
                    }).getKey();

                    userKeys.push(userFirebaseId);

                    return userFirebaseId;
                }
            }).catch(error => {
                console.error("[UserManager] loginToGame, get gamestate promise failed: " + error);
                return -1;
            });
        } else {
            // return error to user
            return -2;
        }
    }).catch(error => {
        console.error("[UserManager] loginToGame, promise failed: " + error);
        return -2;
    })       

}*/

// REVERTED TO THIS FROM V0.8.3
export async function loginToGame(playerName, gameId, isHost) {

    console.log("[UserManager] loginToGame called, isHost: " + isHost);

    playerList.push(playerName);

    if (!isHost) {
        // need to get a reference to this game first
        currentGameRef = await gameGenerator.joinGame(gameId);
        allUsersRef = currentGameRef.child("users");
        hostStatus = true;
    } else {
        currentGameRef = fbMgr.getGameRef();
        allUsersRef = fbMgr.getUsersRef();
        hostStatus = false;
    }

    if (allUsersRef != null) {

        console.log("[UserManager] allUsersRef!=null");

        // push a new unique user into the user object and store the unique ID of this user
        userFirebaseId = allUsersRef.push({
            name: playerName,
            currentSequence: "",
            sequences: {},
            isHost: isHost,
            stillInGame: true,
            lastSubmittedRound: 0
        }).getKey();

        // add the user ID to userSubmissions object
        let userSubmissionPlaceholder = {};
        userSubmissionPlaceholder[userFirebaseId] = -1;
        currentGameRef.child("userSubmissions").child(userFirebaseId).set(-1);
        
        if(isHost){
            currentGameRef.child("gameData").child("hostId").set(userFirebaseId);
        }

        userKeys.push(userFirebaseId);
        cookieHandler.storeCurrentGame(gameId, userFirebaseId, playerName);
        return userFirebaseId;

    } else {
        // return error to user
        return "Error logging in";
    }
}


export async function canJoinGame(gameId){
    console.log("[JoinUsingCode] JOIN FROM CODE STEP 3: USERMGR CANJOINGAME INVOKED");
    console.log("[UserManager] canJoinGame invoked, gameId: " + gameId)
    return new Promise(function(resolve, reject){
        let gameRef = fbMgr.getGameRef(gameId);
        gameRef.once("value", function(snapshot){
            console.log("[JoinUsingCode] JOIN FROM CODE STEP 4: GAMEREF.ONCE RESOLVED");
            let state = snapshot.val().gameData.state;
            let playerCount = Object.keys(snapshot.val().users).length;
            console.log("[UserManager] canJoinGame, state: " + state + " playerCount: " + playerCount);


        /**
         * resolve true = player can join game
         * reject 0 = game finished, redirect to results view
         * reject -1 = game in progress
         * reject -2 = game full
         * reject -3 = anything else
         */
            if(state=="waitForPlayers" && playerCount<10) resolve(true);
            else if(state=="finished" || state=="resultsFinished") reject(0);
            else if(state!=="waitForPlayers") reject(-1);
            else if(playerCount>=10) reject(-2);
            else reject(-3);

        }).catch(error=>{
            console.error("[UserManager] canJoinGame, error: " + error);
            reject(-3);
        })
    })
}

export function registerUserListener() {
    console.log("[UserManager] registerUserListener, userFirebaseId : " + userFirebaseId + " hostStatus: " + hostStatus + " allUsersRef = " + JSON.stringify(allUsersRef));
    // set a listener for this user
    if(userFirebaseId !== null && userFirebaseId !== undefined){
        userRef = allUsersRef.child(userFirebaseId);
        userRef.on("value", function (userSnapshot) {
            userStateChanged(userSnapshot.val());
        })

        setUserDisconnectHandler();
    }
    // TODO: RE-SETUP THE DISCONNECT HANDLER WHEN USER HAS BEEN MADE INTO HOST
}

export function setUserDisconnectHandler(){
    console.log("[UserManager] setUserDisconnectHandler invoked");

    if(userFirebaseId !== null && userFirebaseId !== undefined){

        if(userRef === null || userRef === undefined) userRef = allUsersRef.child(userFirebaseId);

        // register disconnect handler
        userRef.onDisconnect().update({
            "stillInGame" : false,
            "isHost" : false,
            "isForeground" : appInForeground 
        });
    }

    if(hostStatus && currentGameRef !== null && currentGameRef !== undefined){
        currentGameRef.child("gameData").onDisconnect().update({
            "hostIsDisconnected" : true
        })
    }
}

export function setHostRole(status){
    hostStatus = status;

    // re-register the user listener as need to add the hostIsDisconnected onDisconnect call
    registerUserListener();
    

    if(hostChangeCb !== null && hostChangeCb !== undefined){
        console.log("[UserManager] setHostRole invoked, calling hostChangeCb");
        hostChangeCb(status);
    }else{
        console.error("[UserManager] setHostRole invoked, but no hostChangeCb is set");
    }
    
}

export function getStartPos() {
    return new Promise(function (resolve, reject) {

        //if(userRef==null) reject();

        console.log("[UserManager] getStartPos called");

        userRef.once("value", function (data) {
            console.log("[UserManager] getStartPos data read: " + JSON.stringify(data));
            startPos = data
                .val()
                .startPos;
            console.log("[UserManager] getStartPos returning startpos: " + startPos);

            if (startPos !== null && startPos !== undefined)
                resolve(startPos);
            else {
                registerStartPosListener();
                reject();
            }
        });

    });
}

export function registerStartPosListener() {
    console.log("[UserManager] registerStartPosListener called");
    userRef
        .child("startPos")
        .on("value", function (data) {

            console.log("[UserManager] startPosChange: " + JSON.stringify(data));
            startPos = data.val();
            if (startPos !== null && !notifiedOfStartPos) {

                notifiedOfStartPos = true;
                console.log("[UserManager] startPosChange pos not null, so removing listener");

                userRef
                    .child("startPos")
                    .off("value");

                console.log("[UserManager] startPosChange calling gameMgr.onStartPosChange: " + startPos);

                gameMgr.onStartPosChange(startPos);
            }
        });
}

async function setupDbRefs(gameId) {}

function userStateChanged(userSnapshot) {
    console.log("[UserManager] userStateChanged, userSnapshot: " + userSnapshot);
    if(userSnapshot !== null) currentSequence = userSnapshot.currentSequence;

    if (userSnapshot.prompt && promptNotAlreadyProvided) {
        console.log("[UserManager] userStateChanged - calling set prompt")
        gameMgr.setPrompt(userSnapshot.prompt);
        promptNotAlreadyProvided = false;
    }

    if(userSnapshot.isHost && userSnapshot.isHost !== hostStatus){
        setHostRole(userSnapshot.isHost);
    }

    // console.log("[UserManager] userStateChanged Current sequence = " +
    // currentSequence);
}


// export function handleDisconnect(fbRef){
//     if(userFirebaseId!==null & userFirebaseId!==undefined && userRef!==null && userRef!==undefined){
//         if(hostStatus){
//             console.log("[UserManager] handle disconnect invoked. User is host so need to mark both user and host as disconnected");
//             userRef.child("stillInGame").onDisconnect().update(false);
//             currentGameRef.child("gameData").onDisconnect().update({
//                 "hostIsDisconnected" : true
//             })
            
//         }else{
//             console.log("[UserManager] handle disconnect invoked. User is not host so just need to mark user as disconnected");
//             // set the current user as not in game
//             userRef.child("stillInGame").onDisconnect().update(false);
//         }
//     }
// }

export async function handleReconnect(userId, gameToRejoin){

    console.log("[UserManager] handleReconnect invoked, userId: " + userId + " gameId: " + gameToRejoin);
    userFirebaseId = userId;

    // Get game reference
    currentGameRef = await gameGenerator.joinGame(gameToRejoin);
    allUsersRef = currentGameRef.child("users");
    
    let userStillInGame = await checkIfUserStillInGame(userId);
    //if(userStillInGame) 

    // Register user listener
    registerUserListener();

    // Check the data obtained by userStateChanged


    // Set still in game = true
    userRef.update({
        "stillInGame" : true,
        "isForeground" : true
    })

    // Return success or failure
}

async function checkIfUserStillInGame(userId){
    if(userRef===null || userRef === undefined) userRef = allUsersRef.child(userId);
    
    userRef.once("value", function(data){
        console.log("[UserManager] checkIfUserStillInGame, data: " + JSON.stringify(data))
        console.log("[UserManager] checkIfUserStillInGame, user state: " + data.stillInGame);
        return data.stillInGame;
    })
}

export async function getNameAndSubmitState(){
    console.log("[UserManager] getName invoked");

    let userResult = await userRef.once("value", function(snapshot){
        let name = snapshot.val().name;
        let submitState = snapshot.val().submitted;
        console.log("[UserManager] getNameAndSubmitState success. Name: " + name + " submitted: " + submitState);

        // let result = {
        //     "name" : name,
        //     "submitted" : submitState
        // }
        //return result;
        //return snapshot.val();
    // }).then(result => {
    //     userData = result;
        return snapshot;
    }).catch(error => {
        console.error("[UserManager] getName error: " + JSON.stringify(error));
        return false;
    })

    let name = userResult.val().name;
    let submitState = userResult.val().submitted;
    let isHost = userResult.val().isHost;

    let userData = {
        "playerName" : name,
        "submitted" : submitState,
        "isHost" : isHost
    }
    console.log("[UserManager] getNameAndSubmitState, returning result obj: " + JSON.stringify(userData));

    return userData;
}