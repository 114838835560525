import * as userMgr from "./UserManager";

var pageVisible = true; 

function getBrowserVisibilityProp() {
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        return "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
        return "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
        return "webkitvisibilitychange"
    }
}

function getBrowserDocumentHiddenProp() {
    if (typeof document.hidden !== "undefined") {
        return "hidden"
    } else if (typeof document.msHidden !== "undefined") {
        return "msHidden"
    } else if (typeof document.webkitHidden !== "undefined") {
        return "webkitHidden"
    }
}

function getIsDocumentHidden() {
    return !document[getBrowserDocumentHiddenProp()]
}

// https://stackoverflow.com/a/52588765/3414962 and https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react
export function registerAppVisibilityChangeListener(){
    console.log("[AppVisbilityHandler] registerAppVisibilityChangeListener invoked");
    let visibilityProp = getBrowserVisibilityProp;
    let hiddenProp = getBrowserDocumentHiddenProp
    document.addEventListener(visibilityProp,()=>{
        console.log("[AppVisbilityHandler] visibilityChange, state: " + document.visibilityState, false);
    });


    document.addEventListener(visibilityProp, handleVisibilityChange(), false);

    window.addEventListener('focus', function() {
        console.log("[AppVisbilityHandler] focus event");
        handleVisibilityChange(true)
    }, false);

    window.addEventListener('blur', function() {
        console.log("[AppVisbilityHandler] blur event");
        handleVisibilityChange(false)
    }, false);
}


function handleVisibilityChange(state) {

    console.log("[AppVisibilityHandler] handleVisibilityChange invoked")

    if(state===undefined || state===null){
        pageVisible = !document[getBrowserDocumentHiddenProp()]
    }else{
        pageVisible = state;
    }

    console.log("[AppVisbilityHandler] pageVisible", pageVisible);
    
    userMgr.setVisibility(pageVisible)
  }