// let resultsCookie = null;
// let currentGameCookie = null;

// /**
//  * This function stores the game ID and the date so that people can then retrieve 
//  */
// export function updateResultCookie(gameId) {

//     let date = new Date();
//     const options = {
//         year: 'numeric',
//         month: 'long',
//         day: 'numeric'
//     };
//     let dateStr = date.toLocaleDateString(options);
//     let newResult = JSON.stringify({
//         "date": dateStr,
//         "gameId": gameId
//     });
//     if(resultsCookie!=null) resultsCookie.push(newResult);
//     else resultsCookie = [newResult];
//     document.cookie =
//         "results=" + JSON.stringify(resultsCookie) + "; expires=Wed, 1 Jan 2031 00:00:00 UTC; path=/";
// }

// export function processCookies() {

//     // Convert cookie string to obj (https://stackoverflow.com/a/50452780)
//     let cookies = document.cookie.split('; ').reduce((prev, current) => {
//         const [name, value] = current.split('=');
//         prev[name] = value;
//         return prev
//       }, {});

//     // check whether there is a cookie for a game in progress
//     if(cookies.currentGameId){
//         // check whether game is still active
//     }

//     // check whether there is a cookie for results
//     if (cookies.results) {
//         // store the existing results object
//         resultsCookie = cookies.results;
//     }
// }

// export function resetGameIdCookie(){
//     document.cookie = "currentGameId"
// }

let cookieObj;

/*
 * Read cookies and extract to an object
 */
function cookieToObj() {
    console.log("[CookieHandler] cookieToObj invoked");
    return new Promise(function (resolve, reject) {
        let cookies = document.cookie
            .split(';')
            .reduce((res, c) => {
                const [key, val] = c.trim().split('=').map(decodeURIComponent)
                try {
                    return Object.assign(res, {
                        [key]: JSON.parse(val)
                    })
                } catch (e) {
                    return Object.assign(res, {
                        [key]: val
                    })
                }
            }, {});
        console.log("[CookieHandler] cookieToObj finished, returning: " + JSON.stringify(cookies));
        resolve(cookies);
    })

}

/*
 * Update results list cookie
 */
// export function updatePrevGames(gameId) {
//     return new Promise((resolve,reject)=>{
//         let date = new Intl.DateTimeFormat('en-GB').format(new Date(Date.now()));
//         console.log("CookieHandler] updatePrevGames called, gameId: " + gameId + " cookieObj.prev: " + JSON.stringify(cookieObj.prev));
//         // let gameObjToSave = JSON.stringify({
//         //     "gameId" : gameId,
//         //     "date" : date
//         // });

//         let gameObjToSave = {};
//         gameObjToSave[gameId] = date;
//         let gameStr = JSON.stringify(gameObjToSave);


//         let gamesFromLocalStorage = localStorage.getItem("prevGames");
//         if (gamesFromLocalStorage !== null && gamesFromLocalStorage !== undefined) {
//             console.log("CookieHandler] updatePrevGames, prev not null");

//             // First, need to break into an array of objects
//             let prevGameArr = gamesFromLocalStorage.split("@");

//             prevGameArr.push(gameStr);

//             console.log("[CookieHandler] prevGameArr: " + prevGameArr);

//             // we're going to store the game objects with an @ sign as a delimiter
//             let prevGameListStr = "";
//             for(var i=0; i<prevGameArr.length; i++){
//                 if(prevGameArr[i]) prevGameListStr = prevGameArr[i] + "@" + prevGameListStr
//             }

//             console.log("[CookieHandler] setting prevGames to: " + prevGameListStr);

//             localStorage.setItem("prevGames", prevGameListStr);

//             resolve();

//             //document.cookie = "prev=" + prevGameArr + "; expires=Wed, 1 Jan 2031 00:00:00 UTC;";
//         //} else if (cookieObj !== null && cookieObj !== undefined && cookieObj.prev == null) {
//         } else {
//             gameStr = gameStr + "@"

//             console.log("CookieHandler] updatePrevGames, prev IS null. Setting prevGames to: " + gameStr);
//             localStorage.setItem("prevGames", gameStr);
//             resolve();
//             //document.cookie = "prev=\"" + gameObjToSave + "\"; expires=Wed, 1 Jan 2031 00:00:00 UTC;";
//         }
//     })
    
// }

export function updatePrevGames(gameId) {
    return new Promise((resolve,reject)=>{
        let date = new Intl.DateTimeFormat('en-GB').format(new Date(Date.now()));
        console.log("CookieHandler] updatePrevGames called, gameId: " + gameId + " cookieObj.prev: " + JSON.stringify(cookieObj.prev));
        // let gameObjToSave = JSON.stringify({
        //     "gameId" : gameId,
        //     "date" : date
        // });


        let gamesFromLocalStorage = localStorage.getItem("prevGames");
        if (gamesFromLocalStorage !== null && gamesFromLocalStorage !== undefined) {
            console.log("CookieHandler] updatePrevGames, prev not null");

            // First, need to break into an array of objects
            let newGameStr = gamesFromLocalStorage.slice(0,1) + '\"' + gameId + '\":\"' + date + '\",' + gamesFromLocalStorage.slice(1);

            console.log("[CookieHandler] newGameStr: " + newGameStr);

            console.log("[CookieHandler] setting prevGames to: " + newGameStr);

            localStorage.setItem("prevGames", newGameStr);

            resolve();

            //document.cookie = "prev=" + prevGameArr + "; expires=Wed, 1 Jan 2031 00:00:00 UTC;";
        //} else if (cookieObj !== null && cookieObj !== undefined && cookieObj.prev == null) {
        } else {
            let newGameStr = '{\"' + gameId + '\":\"' + date + '\"}'

            console.log("CookieHandler] updatePrevGames, prev IS null. Setting prevGames to: " + newGameStr);
            localStorage.setItem("prevGames", newGameStr);
            resolve();
            //document.cookie = "prev=\"" + gameObjToSave + "\"; expires=Wed, 1 Jan 2031 00:00:00 UTC;";
        }
    })
    
}

// let game1 = JSON.stringify({"id" : "abc", "date" : "3rd Jan"});
// let game2 = JSON.stringify({"id" : "123", "date" : "4tg Jan"});
// let gameArr = [game1, game2];
// document.cookie = "prevtest=" + gameArr + "; expires=Wed, 1 Jan 2031 00:00:00 UTC;"

export function readPrevGames() {
    let _this = this;
    return new Promise(function (resolve, reject) {
        let gamesFromLocalStorage = localStorage.getItem("prevGames");
        if(gamesFromLocalStorage!==null && gamesFromLocalStorage!==undefined){
            console.log("[CookieHandler] readPrevGames, prevGames not null so resolving with data: " + gamesFromLocalStorage);
            resolve(JSON.parse(gamesFromLocalStorage));
            /*let prevGameArr = gamesFromLocalStorage.split("@");
            for(var i=0; i<prevGameArr.length; i++){
                // if the current item is empty (i.e. is where the delimiter would've been), remove it
                if(!prevGameArr[i]) prevGameArr.splice(i,1);
            }

            console.log("[CookieHandler] readPrevGames, prevGameArr sanitised so resolving: " + prevGameArr);

            resolve(prevGameArr);*/
        }else{
            reject("No previous games");
        }
        // if (cookieObj !== null && cookieObj !== undefined && cookieObj.prev !== null && cookieObj.prev !== undefined) {
        //     resolve(cookieObj.prev);
        // } else {
        //     reject("No previous games");
        // }
    });
}

//export function updatePrevGames(gameId){
//}

export function storeCurrentGame(gameId, playerId, name) {
    console.log("[CookieHandler] storeCurrentGame, gameId: " + gameId + " playerId: " + playerId, " name: " + name);
    let expiryString = getExpiryString();
    document.cookie = "gameId=" + gameId + "; " + expiryString;
    document.cookie = "playerId=" + playerId + "; " + expiryString;
    document.cookie = "name=" + name + "; " + expiryString;

}

/**
 * This function returns an expiry time string where the cookie expires 30mins from now
 */
function getExpiryString() {
    let now = new Date();
    now.setTime(now.getTime() + 0.5 * 3600 * 1000);

    let expiryString = "expires=" + now.toUTCString() + ";";

    return expiryString;
}

export function getCurrentGameFromCookie() {
    let _this = this;
    return new Promise(function (resolve, reject) {
        if (cookieObj !== null && cookieObj !== undefined && cookieObj.gameId !== null && cookieObj.gameId !== undefined && cookieObj.playerId !== undefined && cookieObj.playerId !== null) {
            // If the cookie contains a gameId and playerId, retun them as an object
            let gameObj = {
                "gameId": cookieObj.gameId,
                "playerId": cookieObj.playerId
            }
            resolve(gameObj);
        } else {
            reject(false);
        }
    });
}

export function resetCurrentGame() {
    document.cookie = "gameId=null";
    document.cookie = "playerId=null";
}

export function cookieManagerInit() {
    console.log("[CookieHandler] cookieManagerInit invoked");
    return new Promise(function (resolve, reject) {
        // get the current cookies
        cookieToObj().then(cookies => {
            console.log("[CookieHandler] cookieManagerInit cookieToObj resolved, cookieObj: " + JSON.stringify(cookies));
            cookieObj = cookies;
            resolve(true);
        }).catch(error => {
            console.error("[CookieHandler] cookieManagerInit error: " + JSON.stringify(error));
            reject(false);
        })
    })

}