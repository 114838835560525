import React, {Component} from "react";
//import * as gameMgr from "./utils/GameManager";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Button } from "@material-ui/core";

class Fof extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "Oh dear, that page couldn't be found!",
            //secondaryMessage: this.props.location.state.secondaryMessage
        };
    }



    render() {
        const {secondaryMessage} = this.state;
        return (
            <div>
                    <Typography component="h4"
                    variant="h4"
                    align="center"
                    color="textPrimary"
                    gutterBottom>Error: {this.state.errorMessage}</Typography>

                    {secondaryMessage!==null && <Typography
                    component="h5"
                    variant="h5"
                    align="center"
                    color="textPrimary"
                    gutterBottom>{this.state.secondaryMessage}</Typography>}

                    <Button></Button>

            </div>
        );
    }
}

export default Fof;